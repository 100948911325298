import * as i0 from '@angular/core';
import { Component, Input } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
const _c0 = a0 => ({
  "aviatar-icon_inline": a0
});
class IconComponent {
  constructor() {
    this.inline = false;
  }
  static {
    this.ɵfac = function IconComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || IconComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: IconComponent,
      selectors: [["aviatar-icon"]],
      inputs: {
        path: "path",
        inline: "inline"
      },
      decls: 2,
      vars: 4,
      consts: [["viewBox", "0 0 24 24", 1, "aviatar-icon", 3, "ngClass"]],
      template: function IconComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵnamespaceSVG();
          i0.ɵɵelementStart(0, "svg", 0);
          i0.ɵɵelement(1, "path");
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(2, _c0, ctx.inline));
          i0.ɵɵadvance();
          i0.ɵɵattribute("d", ctx.path);
        }
      },
      dependencies: [CommonModule, i1.NgClass],
      styles: [".aviatar-icon[_ngcontent-%COMP%]{fill:currentColor;width:1em}.aviatar-icon_inline[_ngcontent-%COMP%]{vertical-align:-.125em}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IconComponent, [{
    type: Component,
    args: [{
      selector: 'aviatar-icon',
      imports: [CommonModule],
      template: "<svg viewBox=\"0 0 24 24\" class=\"aviatar-icon\" [ngClass]=\"{'aviatar-icon_inline': inline}\">\n    <path [attr.d]=\"path\"></path>\n</svg>\n",
      styles: [".aviatar-icon{fill:currentColor;width:1em}.aviatar-icon_inline{vertical-align:-.125em}\n"]
    }]
  }], null, {
    path: [{
      type: Input
    }],
    inline: [{
      type: Input
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { IconComponent };
